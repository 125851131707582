import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/react';
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import Auth from '../../utils/auth';
function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

export default function Navbar() {
	const { user, isLoggedIn } = useUser();
	const location = useLocation();
	let navigation = [];

	if (user && isLoggedIn) {
		navigation = [
			{ name: 'Home', href: '/', current: location.pathname === '/' },
			{ name: 'Dashboard', href: '/dashboard', current: location.pathname === '/dashboard' },
			{ name: 'Tracker', href: '/tracker', current: location.pathname === '/tracker' },
			{ name: 'Streaks', href: '/streaks', current: location.pathname === '/streaks' },
		];
	} else {
		navigation = [
			// { name: 'Home', href: '/', current: location.pathname === '/' },
			{ name: 'Sign In', href: '/signin', current: location.pathname === '/signin' },
			// { name: 'Sign Up', href: '/signup', current: location.pathname === '/signup' },
		];
	}

	return (
		<Disclosure
			as='nav'
			className='bg-gray-800'
		>
			<div className='mx-auto max-w-7xl px-2 sm:px-6 lg:px-8'>
				<div className='relative flex h-16 items-center justify-between'>
					<div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
						{/* Mobile menu button*/}
						<DisclosureButton className='group relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
							<span className='absolute -inset-0.5' />
							<span className='sr-only'>Open main menu</span>
							<Bars3Icon
								aria-hidden='true'
								className='block h-6 w-6 group-data-[open]:hidden'
							/>
							<XMarkIcon
								aria-hidden='true'
								className='hidden h-6 w-6 group-data-[open]:block'
							/>
						</DisclosureButton>
					</div>
					<div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
						<div className='flex flex-shrink-0 items-center'>
							<Link to='/'>
								<img
									alt='Your Company'
									src='https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=500'
									className='h-8 w-auto'
								/>
							</Link>
						</div>
						<div className='hidden sm:ml-6 sm:block'>
							<div className='flex space-x-4'>
								{navigation.map((item) => (
									<NavLink
										key={item.name}
										to={item.href}
										aria-current={item.current ? 'page' : undefined}
										className={classNames(
											item.current
												? 'bg-gray-900 text-white'
												: 'text-gray-300 hover:bg-gray-700 hover:text-white',
											'rounded-md px-3 py-2 text-sm font-medium'
										)}
									>
										{item.name}
									</NavLink>
								))}
							</div>
						</div>
					</div>
					{user && user.username && (
						<div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
							<button
								onClick={Auth.logout}
								type='button'
								className='relative rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'
							>
								<p>Logout</p>
							</button>
						</div>
					)}
				</div>
			</div>

			<DisclosurePanel className='sm:hidden'>
				<div className='space-y-1 px-2 pb-3 pt-2'>
					{navigation.map((item) => (
						<DisclosureButton
							key={item.name}
							as='a'
							href={item.href}
							aria-current={item.current ? 'page' : undefined}
							className={classNames(
								item.current
									? 'bg-gray-900 text-white'
									: 'text-gray-300 hover:bg-gray-700 hover:text-white',
								'block rounded-md px-3 py-2 text-base font-medium'
							)}
						>
							{item.name}
						</DisclosureButton>
					))}
				</div>
			</DisclosurePanel>
		</Disclosure>
	);
}
