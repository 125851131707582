import React, { useState } from 'react';

// Modal Component
const Modal = ({ isOpen, onClose, content }) => {
	if (!isOpen) return null;

	return (
		<div className='z-40 fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center'>
			<div className='bg-white rounded-lg shadow-lg w-96 p-6 relative'>
				<h2 className='text-lg font-bold mb-4'>{content.title}</h2>
				<p className='mb-6'>{content.body}</p>
				<button
					className='bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition'
					onClick={onClose}
				>
					{content.closeButtonText || 'Close'}
				</button>
			</div>
		</div>
	);
};

// NestedModals Component
const NestedModals = ({
	open = false,
	modals = [
		{
			title: "You're doing great!",
			body: "Good job little baby! I'm so proud of you.",
			closeButtonText: 'Close',
		},
		{
			title: 'LOLLLLLZZZZ',
			body: 'You thought this was going to be the last popup? Gotcha!',
			closeButtonText: 'Close for real',
		},
		{
			title: 'GOT YOU AGAIN!',
			body: 'wow......',
			closeButtonText: 'Finish',
		},
		{
			title: 'Important!',
			body: 'send me nudes... ok bye',
			closeButtonText: 'Bye',
		},
		{
			title: 'JK',
			body: 'ok bye for real this time',
			closeButtonText: 'Close',
		},
	],
}) => {
	const [currentModalIndex, setCurrentModalIndex] = useState(0);

	const handleClose = () => {
		if (currentModalIndex < modals.length - 1) {
			setCurrentModalIndex(currentModalIndex + 1);
		} else {
			setCurrentModalIndex(-1); // Close all modals
		}
	};

	return (
		<>
			{open &&
				modals.map((modal, index) => (
					<Modal
						key={index}
						isOpen={currentModalIndex === index}
						onClose={handleClose}
						content={modal}
					/>
				))}
		</>
	);
};

export default NestedModals;
