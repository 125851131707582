import { Link } from 'react-router-dom';

export default function LandingPage() {
	return (
		<div className='bg-white px-6 py-24 sm:py-32 lg:px-8'>
			<div className='mx-auto max-w-2xl text-center'>
				<p className='text-base font-semibold leading-7 text-indigo-600'>Get the help you need</p>
				<h2 className='mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl'>
					Sobriety Tracker
				</h2>
				<p className='mt-6 text-lg leading-8 text-gray-600'>
					You can do this! I love you so much and I want to see you shine. Let's get started.
				</p>
				<div className='mt-10'>
					<Link to='/tracker'>
						<button
							type='button'
							className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
						>
							Tracker
						</button>
					</Link>
				</div>
			</div>
		</div>
	);
}
