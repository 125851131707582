import React, { useEffect, useState } from 'react';

const CountdownTimer = ({ date }) => {
	const calculateTimeLeft = () => {
		const now = new Date();
		const difference = new Date(date) - now;

		if (difference > 0) {
			return {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		} else {
			return { days: 0, hours: 0, minutes: 0, seconds: 0 };
		}
	};

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer);
	}, [date]);

	return (
		<div className='flex space-x-4'>
			<div>
				<span className='text-2xl font-bold'>{timeLeft.days}</span> days
			</div>
			<div>
				<span className='text-2xl font-bold'>{timeLeft.hours}</span> hrs
			</div>
			<div>
				<span className='text-2xl font-bold'>{timeLeft.minutes}</span> mins
			</div>
			<div>
				<span className='text-2xl font-bold'>{timeLeft.seconds}</span> secs
			</div>
		</div>
	);
};

export default CountdownTimer;
