import React, { useEffect, useRef, useState } from 'react';
import Spinner from '../Global/Spinner';
import { useNavigate } from 'react-router-dom';
import client from '../../api/client';
import { useAlert } from '../../context/AlertContext';
import Badges from './Badges';
import PhotoUpload from './PhotoUpload';
import { useUser } from '../../context/UserContext';
import ConfettiComponent from '../Global/Confetti';
import NestedModals from '../Global/NestedModals';

export default function Tracker() {
	const [loading, setLoading] = useState(false);
	const [days, setDays] = useState(0);
	const [uploading, setUploading] = useState(false);
	const [imageFile, setImageFile] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [showNestedModal, setShowNestedModal] = useState(false);
	const navigate = useNavigate();
	const { setAlert } = useAlert();
	const { user, isLoggedIn } = useUser();

	const confettiRef = useRef(null);

	const handleConfetti = () => {
		if (confettiRef.current) {
			confettiRef.current.fireConfetti();
		}
	};

	const handleSubmit = async (e, isSober) => {
		e.preventDefault();
		try {
			setLoading(true);
			const formData = new FormData();
			formData.append('image', imageFile);
			formData.append('isSober', isSober);

			if (isSober && confettiRef.current) {
				confettiRef.current.fireConfetti();
			}
			const { data } = await client.post('/sobriety', formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			});

			setDays(data.totalSoberDays);
			fetchDays();
			setImageFile(null);
			if (isSober) {
				setAlert('success', 'Good Job!!! You are doing great!');
				setShowNestedModal(true);
			} else {
				setAlert(
					'info',
					"Don't let this make you spiral \nYou are still doing great and can start over tomorrow!"
				);
			}

			setLoading(false);
		} catch (error) {
			console.log({ error });
			setLoading(false);
			setAlert('error', error.response.data.message);
		}
	};

	const handleImageChange = async (e) => {
		const file = e.target.files[0];
		if (!file) return;

		if (file.size > 5242880) {
			// 5MB in bytes
			const resizedImage = await resizeImage(file);
			setImageFile(resizedImage);
		} else {
			setImageFile(file);
		}
	};

	// Helper function to resize the image
	const resizeImage = (file) => {
		return new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = (event) => {
				const img = new Image();
				img.src = event.target.result;

				img.onload = () => {
					const canvas = document.createElement('canvas');
					const maxWidth = 1024; // Resize to a max width
					const scaleSize = maxWidth / img.width;

					canvas.width = maxWidth;
					canvas.height = img.height * scaleSize;

					const ctx = canvas.getContext('2d');
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

					canvas.toBlob(
						(blob) => {
							resolve(blob); // Resolve with resized image blob
						},
						'image/jpeg',
						0.8
					); // Set quality to 80%
				};

				img.onerror = (error) => reject(error);
			};
			reader.onerror = (error) => reject(error);
		});
	};

	const fetchDays = async () => {
		try {
			setLoading(true);
			const { data } = await client.get('/sobriety', {
				headers: { Authorization: `Bearer ${sessionStorage.getItem('token')}` },
			});
			setDays(data.totalSoberDays);
			setLoading(false);
		} catch (error) {
			setLoading(false);
			setAlert('error', error.response.data.message);
		}
	};

	useEffect(() => {
		fetchDays();
	}, []);

	const confirmReset = () => {
		setShowModal(false);
		handleSubmit(new Event('submit'), false);
	};
	if (loading) return <Spinner />;
	return (
		<div className='bg-white py-8 sm:py-10'>
			<ConfettiComponent ref={confettiRef} />
			<NestedModals open={showNestedModal} />
			<div className='mx-auto max-w-2xl px-6 lg:max-w-7xl lg:px-8'>
				<h2 className='text-base font-semibold text-indigo-600'>Sobriety Tracker</h2>
				<p className='mt-2 max-w-lg text-pretty text-4xl font-medium tracking-tight text-gray-950 sm:text-5xl'>
					Track your progress and stay motivated.
				</p>
				<p className='mt-2 text-lg text-center font-medium tracking-tight text-gray-950'>
					<span className='text-5xl font-bold'>{days || 0}</span>
					<span className='block'>Days Sober</span>
				</p>

				<div className='mt-4 grid grid-cols-1 gap-4 sm:mt-16 lg:grid-cols-6 lg:grid-rows-2'>
					<div className='relative lg:col-span-3'>
						<div className='absolute inset-px rounded-lg bg-white max-lg:rounded-t-[2rem] lg:rounded-tl-[2rem]' />
						<div className='relative flex h-full flex-col overflow-hidden rounded-lg shadow ring-1 ring-black/5'>
							<div className='p-10 pt-4'>
								<h3 className='text-sm font-semibold text-indigo-600'>Your Progress</h3>
								<Badges days={days} />
							</div>
						</div>
					</div>

					<div className='relative lg:col-span-3'>
						<div className='absolute inset-px rounded-lg bg-white lg:rounded-tr-[2rem]' />
						<div className='relative flex h-full flex-col overflow-hidden rounded-lg shadow ring-1 ring-black/5'>
							<div className='p-10 pt-4'>
								<h3 className='text-sm font-semibold text-indigo-600'>Actions</h3>
								<div className='mt-8 flex flex-col items-center space-y-6'>
									<button
										onClick={(e) => handleSubmit(e, true)}
										className='w-full max-w-xs rounded bg-emerald-600 py-4 text-white text-2xl font-bold transition hover:bg-emerald-500 shadow-lg'
									>
										Record a sober day!
									</button>
									<button
										onClick={() => setShowModal(true)} // Show modal on click
										className='w-full max-w-xs rounded bg-rose-600 py-4 text-white text-2xl font-bold transition hover:bg-rose-500 shadow-lg'
									>
										Reset Sober Days
									</button>
								</div>
							</div>
						</div>
					</div>

					<div className='relative lg:col-span-6'>
						<div className='absolute inset-px rounded-lg bg-white max-lg:rounded-b-[2rem]' />
						<div className='relative flex flex-col overflow-hidden rounded-lg shadow ring-1 ring-black/5'>
							<div className='p-3 pt-4'>
								<h3 className='text-sm font-semibold text-indigo-600'>Upload Your Progress</h3>
								<div className='mt-3'>
									<PhotoUpload uploadFunction={handleImageChange} />
								</div>
							</div>
						</div>
					</div>
				</div>
				<footer className='mt-5 text-center'>
					<p className='text-xl font-medium text-gray-600'>I Love You! - Stoops</p>
				</footer>
			</div>

			{/* Confirmation Modal */}
			{showModal && (
				<div className='fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50'>
					<div className='bg-white rounded-lg shadow-lg p-8 pt-2 max-w-md w-full'>
						<h3 className='text-lg font-semibold text-gray-900'>Are you sure?</h3>
						<p className='mt-2 text-gray-600'>
							Do you really want to reset your sober days? This action cannot be undone.
						</p>
						<div className='mt-6 flex justify-end space-x-4'>
							<button
								onClick={() => setShowModal(false)}
								className='bg-gray-500 text-white py-2 px-4 rounded-lg shadow hover:bg-gray-400 transition'
							>
								Cancel
							</button>
							<button
								onClick={confirmReset}
								className='bg-rose-600 text-white py-2 px-4 rounded-lg shadow hover:bg-rose-500 transition'
							>
								Yes, Reset
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
