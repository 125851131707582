import React, { useEffect, useState } from 'react';
import { createBrowserRouter, Navigate, RouterProvider } from 'react-router-dom';
import Signin from './components/Auth/Signin';
import Signup from './components/Auth/Signup';
import CountdownContainer from './components/Countdown/CountdownContainer';
import LandingPage from './components/LandingPage';
import StreakDetails from './components/Streaks/StreakDetails';
import Streaks from './components/Streaks/Streaks';
import Tracker from './components/Tracker/Tracker';
import MainLayout from './layouts/MainLayout';
import Auth, { ACCESS_TOKEN_KEY } from './utils/auth';
import Dashboard from './components/Dashboard/Dashboard';

// ProtectedRoute for routes that require authentication
const ProtectedRoute = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(null);

	useEffect(() => {
		const checkAuth = async () => {
			const token = sessionStorage.getItem(ACCESS_TOKEN_KEY);
			if (token) {
				const { user } = await Auth.getUser(token);
				if (user) {
					setIsAuthenticated(true);
					return;
				}
			}
			setIsAuthenticated(false);
		};
		checkAuth();
	}, []);

	if (isAuthenticated === null) {
		return <div>Loading...</div>; // Show loading until the auth check is complete
	}

	if (!isAuthenticated) {
		return (
			<Navigate
				to='/signin'
				replace
			/>
		); // Redirect to SignIn if not authenticated
	}

	return children; // Render the protected content if authenticated
};

// GuestRoute for routes that should be accessible only when not logged in
const GuestRoute = ({ children }) => {
	const [isAuthenticated, setIsAuthenticated] = useState(null);

	useEffect(() => {
		const checkAuth = async () => {
			const token = sessionStorage.getItem(ACCESS_TOKEN_KEY);
			if (token) {
				const { user } = await Auth.getUser(token);
				if (user) {
					setIsAuthenticated(true);
					return;
				}
			}
			setIsAuthenticated(false);
		};
		checkAuth();
	}, []);

	if (isAuthenticated === null) {
		return <div>Loading...</div>; // Show loading until the auth check is complete
	}

	if (isAuthenticated) {
		return (
			<Navigate
				to='/tracker'
				replace
			/>
		); // Redirect to Tracker if already logged in
	}

	return children; // Render the guest content (Signin, Signup) if not authenticated
};

export default function Router() {
	const routes = [
		{
			path: '',
			element: <MainLayout />,
			children: [
				{ path: '', element: <LandingPage /> },
				{
					path: 'signin',
					element: (
						<GuestRoute>
							<Signin />
						</GuestRoute>
					),
				},
				{
					path: 'signup',
					element: (
						<GuestRoute>
							<Signup />
						</GuestRoute>
					),
				},
			],
		},
		{
			path: '',
			element: (
				<ProtectedRoute>
					<MainLayout />
				</ProtectedRoute>
			),
			children: [
				{ path: 'dashboard', element: <Dashboard /> },
				{ path: 'tracker', element: <Tracker /> },
				{ path: 'streaks', element: <Streaks /> },
				{ path: 'streaks/:streakId', element: <StreakDetails /> },
			],
		},
		{ path: '*', element: <h1>Not Found</h1> },
	];

	const router = createBrowserRouter(routes);

	return (
		<div>
			<RouterProvider router={router} />
		</div>
	);
}
