/**
 * @example
 * import React, { useRef } from 'react';
import ConfettiComponent from './ConfettiComponent';

function App() {
  const confettiRef = useRef(null);

  const handleConfetti = () => {
    if (confettiRef.current) {
      confettiRef.current.fireConfetti();  // Call method to trigger confetti
    }
  };

  return (
    <div className="App">
      <button onClick={handleConfetti}>Blow Confetti!</button>
      <ConfettiComponent ref={confettiRef} />
    </div>
  );
}

export default App;

 */
import React, { forwardRef } from 'react';
import confetti from 'canvas-confetti';

const ConfettiComponent = forwardRef((props, ref) => {
	// Function to trigger confetti explosion
	const fireConfetti = () => {
		confetti({
			particleCount: 200,
			spread: 70,
			origin: { y: 0.6 },
			colors: ['#bb0000', '#ffffff'],
		});
	};

	// Expose fireConfetti method via ref
	React.useImperativeHandle(ref, () => ({
		fireConfetti,
	}));

	return null; // No visible canvas component to interfere with app
});

export default ConfettiComponent;
