import React, { useState } from 'react';
/**
 * 
 * @param {*} param0 
 * @returns 
 * 
 * 
 * @example
const App = () => {
	const accordionItems = [
		{ title: 'Section 1', content: 'Content for Section 1' },
		{ title: 'Section 2', content: 'Content for Section 2' },
		{ title: 'Section 3', content: 'Content for Section 3' },
	];

	return (
		<div className='max-w-md mx-auto mt-10'>
			<h1 className='text-2xl font-bold text-center mb-4'>Accordion Example</h1>
			<Accordion items={accordionItems} />
		</div>
	);
};

export default App;
 */
export const Accordion = ({ items, ...rest }) => {
	const [activeIndex, setActiveIndex] = useState(null);

	const toggleAccordion = (index) => {
		setActiveIndex(activeIndex === index ? null : index);
	};

	return (
		<div
			className='space-y-4 '
			{...rest}
		>
			{items.map((item, index) => (
				<div
					key={index}
					className='border border-gray-200 rounded-lg'
				>
					<button
						onClick={() => toggleAccordion(index)}
						className='flex justify-between w-full px-4 py-2 text-left text-lg font-medium text-gray-800 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring focus:ring-gray-300'
					>
						{item.title}
						<span
							className={`transform transition-transform ${
								activeIndex === index ? 'rotate-180' : ''
							}`}
						>
							▼
						</span>
					</button>
					<div
						className={`overflow-hidden transition-max-height duration-300 ${
							activeIndex === index ? 'max-h-screen' : 'max-h-0'
						}`}
					>
						<div className='px-4 py-2 bg-white'>{item.content}</div>
					</div>
				</div>
			))}
		</div>
	);
};
