import React, { useState } from 'react';
import client from '../../api/client';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import { useAlert } from '../../context/AlertContext';

export default function Signin() {
	const { setUser, setIsLoggedIn, isLoggedIn } = useUser();
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [formData, setFormData] = useState({});
	const { setAlert } = useAlert();
	const handleChange = (e) => {
		const { name, value } = e.target;
		setFormData({ ...formData, [name]: value });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		try {
			setLoading(true);
			const { data } = await client.post('/user/signin', formData);
			if (data) sessionStorage.setItem('token', data.token);
			setUser(data.user);
			setIsLoggedIn(true);
			setLoading(false);
			navigate('/dashboard');
		} catch (error) {
			setLoading(false);
			setAlert('error', 'Invalid username or password');
			console.error({ error });
			return;
		}
	};

	return (
		<div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
			<div className='sm:mx-auto sm:w-full sm:max-w-sm'>
				<img
					className='mx-auto h-10 w-auto'
					src='https://tailwindui.com/plus/img/logos/mark.svg?color=indigo&shade=600'
					alt='Your Company'
				/>
				<h2 className='mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900'>
					Sign in to your account
				</h2>
			</div>

			<div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
				<form
					onSubmit={handleSubmit}
					className='space-y-6'
				>
					<div>
						<label
							htmlFor='username'
							className='block text-sm font-medium leading-6 text-gray-900'
						>
							Username
						</label>
						<div className='mt-2'>
							<input
								id='username'
								name='username'
								type='text'
								required
								autoComplete='username'
								onChange={handleChange}
								className='px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
							/>
						</div>
					</div>

					<div>
						<div className='flex items-center justify-between'>
							<label
								htmlFor='password'
								className='block text-sm font-medium leading-6 text-gray-900'
							>
								Password
							</label>
							<div className='text-sm'>
								<a
									href='#'
									className='font-semibold text-indigo-600 hover:text-indigo-500'
								>
									Forgot password?
								</a>
							</div>
						</div>
						<div className='mt-2'>
							<input
								id='password'
								name='password'
								type='password'
								required
								autoComplete='current-password'
								onChange={handleChange}
								className='px-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6'
							/>
						</div>
					</div>

					<div>
						<button
							type='submit'
							className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
						>
							Sign In
						</button>
					</div>
				</form>

				<p className='mt-10 text-center text-sm text-gray-500'>
					Not a member?{' '}
					<Link
						to='/signup'
						className='font-semibold leading-6 text-indigo-600 hover:text-indigo-500'
					>
						Sign up
					</Link>
				</p>
			</div>
		</div>
	);
}
