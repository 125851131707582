import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/Global/Navbar';
export default function MainLayout() {
	return (
		<div className=''>
			<Navbar />
			<Outlet />
		</div>
	);
}
