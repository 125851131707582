/**
 *  Function to convert a date to Eastern Time
 *
 *
 */
export const toEasternTime = (date) => {
	const utcDate = new Date(date);
	const offset = -5; // Eastern Time is UTC-5 during standard time
	const dstOffset = new Date().getTimezoneOffset() < 240 ? -4 : -5; // Adjust for daylight saving time
	return new Date(utcDate.setHours(utcDate.getUTCHours() + offset + (dstOffset - offset)));
};
