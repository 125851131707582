import React, { createContext, useContext, useState, useEffect } from 'react';
import Auth from '../utils/auth';
const UserContext = createContext();
const UserProvider = ({ children }) => {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [user, setUser] = useState({});
	// const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	// handles auto login if token is present
	const fetchUser = async () => {
		// check for token
		console.log('inside fetch user');
		const token = Auth.getToken();
		if (token) {
			const isExpired = Auth.isTokenExpired(token);
			if (isExpired) {
				Auth.logout();
				setIsLoggedIn(false);
				setUser({});
				return;
			}
			setLoading(true);
			try {
				console.log('token found');

				if (token !== null) {
					// send to backend to validate
					const res = await Auth.getUser(token);
					setIsLoggedIn(true);
					setUser(res.user);
				} else {
					setUser({});
					setIsLoggedIn(false);
				}
				setLoading(false);
			} catch (error) {
				setIsLoggedIn(false);
				return console.error('Error: ' + error.message);
			}
		} else {
			console.log(Auth.getToken());
			return console.log('user is not signed in - no token');
		}
	};

	useEffect(() => {
		fetchUser();
	}, []);
	useEffect(() => {
		console.log({ user });
	}, [user]);
	return (
		<UserContext.Provider
			value={{
				isLoggedIn,
				setIsLoggedIn,
				user,
				setUser,
				loading,
				setLoading,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};
export const useUser = () => useContext(UserContext);
export default UserProvider;
