import React from 'react';
import Progress from './Progress';
export default function Badges({ days }) {
	return (
		<div className='mt-3 align-middle grid grid-cols-2 lg:grid-cols-4'>
			<Progress
				days={days}
				total={1}
				title='1 Day'
			/>
			<Progress
				days={days}
				total={7}
				title='1 Week'
			/>
			<Progress
				days={days}
				total={14}
				title='2 Weeks'
			/>
			<Progress
				days={days}
				total={30}
				title='1 Month'
			/>
			<Progress
				days={days}
				total={90}
				title='3 months'
			/>
			<Progress
				days={days}
				total={180}
				title='6 Months'
			/>
			<Progress
				days={days}
				total={365}
				title='1 Year'
			/>
			<Progress
				days={days}
				total={365 * 2}
				title='2 Years'
			/>
			<Progress
				days={days}
				total={365 * 3}
				title='3 Years'
			/>
		</div>
	);
}
