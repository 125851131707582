import React, { useEffect, useState } from 'react';

const TimeSince = ({ date, title, subtitle }) => {
	const calculateTimeSince = () => {
		const now = new Date();
		const difference = now - date;

		if (difference > 0) {
			return {
				years: Math.floor(difference / (1000 * 60 * 60 * 24 * 365)),
				days: Math.floor((difference / (1000 * 60 * 60 * 24)) % 365),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / (1000 * 60)) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		} else {
			return { years: 0, days: 0, hours: 0, minutes: 0, seconds: 0 };
		}
	};

	const [timeSince, setTimeSince] = useState(calculateTimeSince());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeSince(calculateTimeSince());
		}, 1000);

		return () => clearInterval(timer);
	}, [date]);

	return (
		<div className='my-5 bg-white p-6 rounded shadow-md text-center'>
			{title && <h1 className='text-3xl font-bold mb-4'>{title}</h1>}
			{subtitle && <h3 className='text-xl font-bold mb-4'>{subtitle}</h3>}
			<div>
				<div className='grid grid-cols-5 gap-4 text-xl font-semibold'>
					<div className='flex flex-col'>
						<span className='text-4xl'>{timeSince.years}</span>
						<span>Years</span>
					</div>
					<div className='flex flex-col'>
						<span className='text-4xl'>{timeSince.days}</span>
						<span>Days</span>
					</div>
					<div className='flex flex-col'>
						<span className='text-4xl'>{timeSince.hours}</span>
						<span>Hours</span>
					</div>
					<div className='flex flex-col'>
						<span className='text-4xl'>{timeSince.minutes}</span>
						<span>Minutes</span>
					</div>
					<div className='flex flex-col'>
						<span className='text-4xl'>{timeSince.seconds}</span>
						<span>Seconds</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TimeSince;
