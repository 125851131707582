import React, { useEffect, useState } from 'react';
import client from '../../api/client';
import { Link } from 'react-router-dom';
import Spinner from '../Global/Spinner';

export default function Streaks() {
	const [streaks, setStreaks] = useState([]);
	const [loading, setLoading] = useState(false);
	const fetchStreaks = async () => {
		try {
			setLoading(true);
			const { data } = await client.get('/streaks', {
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem('token')}`,
				},
			});
			setStreaks(data.streaks);
			setLoading(false);
			return data.streaks;
		} catch (error) {
			setLoading(false);
			console.error(error);
		}
	};

	useEffect(() => {
		fetchStreaks();
	}, []);
	if (loading) return <Spinner />;
	return (
		<div className='max-w-4xl mx-auto py-8 px-4'>
			<h1 className='text-4xl font-bold text-center text-gray-800 mb-8'>Streaks</h1>
			{streaks.length > 0 ? (
				streaks.map((streak, idx) => (
					<div
						key={streak._id}
						className='bg-white shadow-md rounded-lg p-6 mb-6 border border-gray-200'
					>
						<h3 className='text-2xl font-semibold text-gray-700'>Streak #{idx + 1}</h3>
						<div className='mt-4'>
							<div className='flex justify-between'>
								<p className='text-lg text-gray-600'>Days:</p>
								<p className='text-lg font-medium text-gray-900'>{streak.uniqueDays}</p>
							</div>
							<div className='flex justify-between mt-2'>
								<p className='text-lg text-gray-600'>Date Started:</p>
								<p className='text-lg font-medium text-gray-900'>
									{new Date(streak.dateStarted).toLocaleDateString()}
								</p>
							</div>
							<div className='flex justify-between mt-2'>
								<p className='text-lg text-gray-600'>Date Ended:</p>
								<p className='text-lg font-medium text-gray-900'>
									{streak.dateEnded ? new Date(streak.dateEnded).toLocaleDateString() : 'Current'}
								</p>
							</div>
						</div>
						<Link
							to={`/streaks/${streak._id}`}
							className='inline-block mt-4 bg-indigo-600 text-white py-2 px-4 rounded-md font-semibold hover:bg-indigo-500 transition duration-200'
						>
							View Streak
						</Link>
					</div>
				))
			) : (
				<p className='text-center text-xl text-gray-600'>
					No streaks found. Start tracking your progress!
				</p>
			)}
		</div>
	);
}
