import React, { useState } from 'react';
import { PhotoIcon } from '@heroicons/react/24/outline';

export default function PhotoUpload({ uploadFunction }) {
	const [uploading, setUploading] = useState(false);
	const [preview, setPreview] = useState(null);

	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setPreview(URL.createObjectURL(file)); // Set the preview URL
			uploadFunction(event); // Call the original upload function
		}
	};

	return (
		<div className='col-span-full'>
			<label
				htmlFor='cover-photo'
				className='block text-sm font-medium leading-6 text-gray-900'
			>
				Cover photo
			</label>
			<div className='mt-2 flex justify-center rounded-lg border border-dashed border-gray-900/25 px-6 py-10'>
				<div className='text-center'>
					{preview ? (
						<img
							src={preview}
							alt='Preview'
							className='mx-auto h-48 w-48 object-cover rounded-lg'
						/>
					) : (
						<>
							<PhotoIcon
								aria-hidden='true'
								className='mx-auto h-12 w-12 text-gray-300'
							/>
							<div className='mt-4 flex text-sm leading-6 text-gray-600'>
								<label
									htmlFor='file-upload'
									className='relative cursor-pointer rounded-md bg-white font-semibold text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 hover:text-indigo-500'
								>
									<span>Upload a file</span>
									<input
										id='file-upload'
										name='file-upload'
										type='file'
										accept='image/*'
										className='sr-only'
										onChange={handleFileChange}
									/>
								</label>
								<p className='pl-1'>or drag and drop</p>
							</div>
							<p className='text-xs leading-5 text-gray-600'>PNG, JPG, GIF up to 10MB</p>
						</>
					)}
				</div>
			</div>
		</div>
	);
}
