import React from 'react';

const Shield = ({ title = '' }) => {
	return (
		<div className='relative w-24 h-24'>
			{/* Cape base */}
			<div className='absolute inset-0 bg-gradient-to-b from-red-700 to-red-800 rounded-b-full shadow-xl border-4 border-yellow-500'></div>
			{/* Crest emblem */}
			<div className='absolute inset-4 bg-yellow-300 border-4 border-yellow-700 rounded-full shadow-md flex items-center justify-center'>
				<span className='text-sm text-center font-bold text-red-800'>{title}</span>
			</div>
			{/* Cape top */}
			<div className='absolute -top-2 left-1/2 transform -translate-x-1/2 w-16 h-4 bg-yellow-700 rounded-t-lg shadow-inner border-2 border-yellow-500'></div>
		</div>
	);
};

export default function Progress({ days = 0, total = 0, title = '' }) {
	const percentage = total > 0 ? (days / total) * 100 : 0;

	return (
		<div className={days >= total ? 'col-span-1' : 'col-span-2 lg:col-span-4'}>
			{days >= total ? (
				<div className='mt-4  flex justify-center'>
					{/* Show one shield when progress is complete */}
					<Shield title={title} />
				</div>
			) : (
				<div className='col-span-2 lg:col-span-4'>
					<h2 className='text-center font-bold text-xl mb-4'>{title}</h2>
					<div className='w-full h-5 rounded border-b text-white font-semibold'>
						<div
							className='h-6 bg-emerald-500 rounded'
							style={{ width: `${percentage}%` }}
						>
							{Math.round(percentage)}%
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
