import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AlertProvider from './context/AlertContext';
import UserProvider from './context/UserContext';
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<AlertProvider>
		<UserProvider>
			<App />
		</UserProvider>
	</AlertProvider>
);
