import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import client from '../../api/client';
import Spinner from '../Global/Spinner';
import formatDate from '../../utils/formatDate';
export default function StreakDetails() {
	const [streakDetails, setStreakDetails] = useState({});
	const { streakId } = useParams();
	const [uploading, setUploading] = useState(false);
	const fetchStreak = async () => {
		try {
			const { data } = await client.get(`/streaks/${streakId}`);
			setStreakDetails(data.streak);
		} catch (error) {
			console.error(error);
		}
	};

	const handleImageUpload = async (dayId, file) => {
		setUploading(true);
		try {
			const today = new Date().toISOString().split('T')[0];
			const formData = new FormData();
			formData.append('image', file);
			formData.append('date', today);
			await client.post(`/streaks/${streakId}/days/${dayId}/upload`, formData, {
				headers: { 'Content-Type': 'multipart/form-data' },
			});
			fetchStreak(); // Refresh data after upload
		} catch (error) {
			console.error('Image upload failed:', error);
		}
		setUploading(false);
	};

	useEffect(() => {
		fetchStreak();
	}, [streakId]);
	if (uploading) return <Spinner />;
	return (
		<div className='max-w-3xl mx-auto py-8 px-4'>
			<h1 className='text-4xl font-bold text-center text-gray-800 mb-8'>Streak Details</h1>
			<div className='bg-white shadow-md rounded-lg p-6 border border-gray-200'>
				<h3 className='text-2xl font-semibold text-gray-700 mb-6'>Streak #{streakDetails._id}</h3>

				<div className='space-y-4'>
					<div className='flex justify-between'>
						<p className='text-lg text-gray-600'>Days:</p>
						<p className='text-lg font-medium text-gray-900'>{streakDetails.uniqueDays}</p>
					</div>
					<div className='flex justify-between'>
						<p className='text-lg text-gray-600'>Date Started:</p>
						<p className='text-lg font-medium text-gray-900'>
							{streakDetails.dateStarted
								? new Date(streakDetails.dateStarted).toLocaleDateString()
								: 'N/A'}
						</p>
					</div>
					<div className='flex justify-between'>
						<p className='text-lg text-gray-600'>Date Ended:</p>
						<p className='text-lg font-medium text-gray-900'>
							{streakDetails.dateEnded
								? new Date(streakDetails.dateEnded).toLocaleDateString()
								: 'Current'}
						</p>
					</div>
				</div>

				{/* Images */}
				<div className='mt-8'>
					<h4 className='text-xl font-semibold text-gray-700 mb-4'>Daily Images</h4>
					<ul
						role='list'
						className='grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1 lg:grid-cols-2 xl:gap-x-8'
					>
						{streakDetails.days &&
							streakDetails.days.map((day, index) => (
								<li
									key={index}
									className='relative'
								>
									{day.imgUrl ? (
										<div className='group aspect-h-7 aspect-w-10 block w-full overflow-hidden rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-100'>
											<img
												src={day.imgUrl}
												alt={`Day ${index + 1}`}
												className='pointer-events-none object-cover group-hover:opacity-75'
											/>
											<button
												type='button'
												className='absolute inset-0 focus:outline-none'
											>
												<span className='sr-only'>View details for {formatDate(day.date)}</span>
											</button>
										</div>
									) : (
										<div className='flex flex-col items-center'>
											<p className='text-gray-600 mb-2'>No image for {formatDate(day.date)}</p>
											{new Date(day.date).toDateString() === new Date().toDateString() && (
												<div>
													<input
														type='file'
														accept='image/*'
														onChange={(e) => handleImageUpload(day._id, e.target.files[0])}
														className='mt-2 block text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-indigo-50 file:text-indigo-600 hover:file:bg-indigo-100'
													/>
													{uploading && <p className='text-gray-500 mt-2'>Uploading...</p>}
												</div>
											)}
										</div>
									)}
									{day.imgUrl && (
										<p className='pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900'>
											{formatDate(day.date)}
										</p>
									)}
								</li>
							))}
					</ul>
				</div>
			</div>
		</div>
	);
}
