// NOTE: this example keeps the access token in sessionStorage just because it's
// simpler, but in a real application you may want to use cookies instead for
// better security
import { jwtDecode } from 'jwt-decode';
import client from '../api/client';
export const isProd = process.env.NODE_ENV === 'production';
export const ACCESS_TOKEN_KEY = 'token';
/**
 * gets token from local storage
 * @returns
 */
function getAccessToken() {
	return sessionStorage.getItem(ACCESS_TOKEN_KEY);
}

/**
 * decodes the token. this only has the token data
 * @returns
 */
function getProfile() {
	const profile = jwtDecode(getToken());
	return profile;
}

function isLoggedIn() {
	const token = getToken();
	// If there is a token and it's not expired, return `true`
	return token && !isTokenExpired(token) ? true : false;
}
function login(token) {
	sessionStorage.setItem(ACCESS_TOKEN_KEY, token);
	window.location.assign('/'); // this refreshes the page which is necessary on login for token
}
function setupVerify(token) {
	sessionStorage.setItem(ACCESS_TOKEN_KEY, token);
	window.location.assign('/verify');
}
function logout() {
	sessionStorage.removeItem(ACCESS_TOKEN_KEY);
	window.location.reload();
	return;
}
function getTokenData(token) {
	const decoded = jwtDecode(token);
	if (decoded) {
		return decoded;
	} else return false;
}
function getToken() {
	return sessionStorage.getItem(ACCESS_TOKEN_KEY);
}
function isTokenExpired(token) {
	// Decode the token to get its expiration time that was set by the server
	const decoded = jwtDecode(token);
	// If the expiration time is less than the current time (in seconds), the token is expired and we return `true`
	if (decoded.exp < Date.now() / 1000) {
		console.log('token expired');
		sessionStorage.removeItem(ACCESS_TOKEN_KEY);
		return true;
	}
	// If token hasn't passed its expiration time, return `false`
	return false;
}
/**
 * User Auth Utility functions
 * @param {*} values
 * @returns
 */
const signup = async (values) => {
	console.log('signup via REST API');
	if (values) console.log('signup values', values);
	try {
		// Cant use local host with react native
		const { data } = await client.post('/user/create', {
			...values,
		});
		// console.log("utils/auth signup data", data);
		return data;
	} catch (error) {
		return console.error(error);
	}
};
const signin = async (values) => {
	console.log('sign in via REST API');
	try {
		// Cant use local host with react native
		const { data } = await client.post('/user/signin', {
			...values,
		});

		if (data.success && data.user) {
			console.log('there is a user');
			const token = data.user.token;
			console.log('setting token');
			console.log(sessionStorage);
			sessionStorage.setItem('token', token);
		}
		return data;
	} catch (error) {
		return console.error(error);
	}
};
const forgotPassword = async (email) => {
	try {
		// Cant use local host with react native
		const { data } = await client.post('/user/forgot-password', {
			email,
		});

		// console.log(data);
		return data;
	} catch (error) {
		return console.error(error);
	}
};
const verifyEmail = async (otp, token) => {
	try {
		let email = '';
		if (sessionStorage.getItem('email')) {
			email = sessionStorage.getItem('email');
		}

		// Cant use local host with react native
		const { data } = await client.post('/user/verify-email', {
			otp,
			token,
		});

		if (data.success && data.user) {
			console.log('data from inside verifyEmail ', data);
		}
		return data;
	} catch (error) {
		return console.error(error);
	}
};
const getUser = async (token) => {
	if (token) {
		try {
			const { data } = await client.get('/user', {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			return data;
		} catch (error) {
			return console.error(error);
		}
	} else {
		return console.error('No Token');
	}
};
const reverify = async (email) => {
	try {
		const { data } = await client.post('/user/reverify', {
			email,
		});
	} catch (error) {
		return console.error(error);
	}
};

/**
 * possible replacements for auth util functions
 */

// login(idToken) {
//   sessionStorage.setItem(ACCESS_TOKEN_KEY, idToken)
//   window.location.assign('/')
// }
// logout() {
//   sessionStorage.removeItem(ACCESS_TOKEN_KEY)
//   window.location.reload()
// }

const Auth = {
	getAccessToken,
	getProfile,
	isLoggedIn,
	logout,
	getTokenData,
	getToken,
	login,
	isTokenExpired,
	signup,
	signin,
	forgotPassword,
	verifyEmail,
	getUser,
	reverify,
	setupVerify,
};
export default Auth;
