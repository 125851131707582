import axios from 'axios';
import Auth from '../utils/auth';

const isProd = window.location.href.includes('https');

const client = axios.create({
	baseURL: isProd ? 'https://sober.keiserdev.com/api' : 'http://localhost:5909/api',
	headers: {
		Authorization: `Bearer ${sessionStorage.getItem('token')}`,
		'Content-Type': 'application/json',
	},
});

export default client;

/**
 * Example usage:
 *
 * import client from './api/client';
 *
 * const getSoberDays = async () => {
 * 	try {
 * 		const { data } = await client.get('/api/sobriety');
 * 		return data.daysSober;
 * 	} catch (error) {
 * 		console.error(error);
 * 	}
 * };
 *
 *
 */
