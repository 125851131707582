import React, { useEffect, useRef } from 'react';
import CountdownContainer from '../Countdown/CountdownContainer';
import ConfettiComponent from '../Global/Confetti';
export default function Dashboard() {
	const confettiRef = useRef(null);

	const handleConfetti = () => {
		if (confettiRef.current) {
			confettiRef.current.fireConfetti(); // Call method to trigger confetti
		}
	};
	useEffect(() => {
		handleConfetti();
	}, []);
	return (
		<div>
			<div className=''>
				<ConfettiComponent ref={confettiRef} />
			</div>
			<CountdownContainer />
		</div>
	);
}
