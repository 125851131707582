import React, { useState } from 'react';
import TimeSince from './TimeSince';
import CountdownTimer from './CountdownTimer';
import { Accordion } from '../Global/Accordion';
import Badges from '../Tracker/Badges';
import { toEasternTime } from '../../utils/toEasternTime';

const CountdownContainer = () => {
	const startDate = toEasternTime('2024-10-06T00:00:00');
	const j6 = toEasternTime('2025-01-06T00:00:00');
	const trumpInauguration = toEasternTime('2025-01-20T00:00:00');
	const month2 = toEasternTime('2024-12-06T00:00:00');
	const month3 = toEasternTime('2025-01-06T00:00:00');
	const month6 = toEasternTime('2025-04-06T00:00:00');
	const month12 = toEasternTime('2025-10-06T00:00:00');
	const month24 = toEasternTime('2026-10-06T00:00:00');
	const month36 = toEasternTime('2027-10-06T00:00:00');

	console.log({ startDate, month2, month3, month6, month12, month24, month36 });

	const accordionItems = [
		{
			title: 'Flight Attendant Training!',
			content: <CountdownTimer date={j6} />,
		},
		{ title: 'Trump Inauguration', content: <CountdownTimer date={trumpInauguration} /> },
		{ title: '2 Months', content: <CountdownTimer date={month2} /> },
		{ title: '3 Months', content: <CountdownTimer date={month3} /> },
		{ title: '6 Months', content: <CountdownTimer date={month6} /> },
		{ title: '1 Year', content: <CountdownTimer date={month12} /> },
		{ title: '2 Years', content: <CountdownTimer date={month24} /> },
		{ title: '3 Years', content: <CountdownTimer date={month36} /> },
	];
	// Function to add months to a date
	function addMonths(date, months) {
		const newDate = new Date(date);
		newDate.setMonth(newDate.getMonth() + months);
		return newDate;
	}

	// Generate dates
	const dates = [];
	for (let months = 2; months <= 48; months++) {
		dates.push(addMonths(startDate, months));
	}

	function daysSince(startDate) {
		const now = new Date();
		const difference = now - startDate;
		return Math.floor(difference / (1000 * 60 * 60 * 24));
	}

	return (
		<div className='min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4'>
			<Accordion
				className='w-full'
				items={[{ title: 'Badges', content: <Badges days={daysSince(startDate)} /> }]}
			/>
			<TimeSince
				date={startDate}
				title='Days Sober'
				subtitle='Since 10/6/2024'
			/>

			{/* accordion */}
			<Accordion items={accordionItems} />
		</div>
	);
};

export default CountdownContainer;

/**
 * 
 * 
 * 
 * 	const startDate = new Date('2024-10-14T00:00:00');

// Function to add months to a date
function addMonths(date, months) {
    const newDate = new Date(date);
    newDate.setMonth(newDate.getMonth() + months);
    return newDate;
}

// Generate dates
const dates = [];
for (let months = 2; months <= 48; months++) {
    dates.push(addMonths(startDate, months));
}
[
    "2024-12-14T05:00:00.000Z",
    "2025-01-14T05:00:00.000Z",
    "2025-02-14T05:00:00.000Z",
    "2025-03-14T04:00:00.000Z",
    "2025-04-14T04:00:00.000Z",
    "2025-05-14T04:00:00.000Z",
    "2025-06-14T04:00:00.000Z",
    "2025-07-14T04:00:00.000Z",
    "2025-08-14T04:00:00.000Z",
    "2025-09-14T04:00:00.000Z",
    "2025-10-14T04:00:00.000Z",
    "2025-11-14T05:00:00.000Z",
    "2025-12-14T05:00:00.000Z",
    "2026-01-14T05:00:00.000Z",
    "2026-02-14T05:00:00.000Z",
    "2026-03-14T04:00:00.000Z",
    "2026-04-14T04:00:00.000Z",
    "2026-05-14T04:00:00.000Z",
    "2026-06-14T04:00:00.000Z",
    "2026-07-14T04:00:00.000Z",
    "2026-08-14T04:00:00.000Z",
    "2026-09-14T04:00:00.000Z",
    "2026-10-14T04:00:00.000Z",
    "2026-11-14T05:00:00.000Z",
    "2026-12-14T05:00:00.000Z",
    "2027-01-14T05:00:00.000Z",
    "2027-02-14T05:00:00.000Z",
    "2027-03-14T05:00:00.000Z",
    "2027-04-14T04:00:00.000Z",
    "2027-05-14T04:00:00.000Z",
    "2027-06-14T04:00:00.000Z",
    "2027-07-14T04:00:00.000Z",
    "2027-08-14T04:00:00.000Z",
    "2027-09-14T04:00:00.000Z",
    "2027-10-14T04:00:00.000Z",
    "2027-11-14T05:00:00.000Z",
    "2027-12-14T05:00:00.000Z",
    "2028-01-14T05:00:00.000Z",
    "2028-02-14T05:00:00.000Z",
    "2028-03-14T04:00:00.000Z",
    "2028-04-14T04:00:00.000Z",
    "2028-05-14T04:00:00.000Z",
    "2028-06-14T04:00:00.000Z",
    "2028-07-14T04:00:00.000Z",
    "2028-08-14T04:00:00.000Z",
    "2028-09-14T04:00:00.000Z",
    "2028-10-14T04:00:00.000Z"
]
 */
